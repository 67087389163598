import React, { useEffect, useMemo, useState } from "react";
import "./style.css";

const texts = [
  "Israel is being attacked not just by land, air and sea, but on the digital plane as well. The battle of public opinion is more important than ever, as lies, propaganda and blatant disinformation spread increasingly quickly.",
  "This website is a resource for anyone who is interested in sharing the truth, defending Israel and ensuring that facts remain ahead of fiction. All information here has been verified by the Consulate General of Israel in New York, and can be used at your discretion to make the case for Israel in these difficult times.",
];

export const MissionPage = () => {
  const [tik, setTik] = useState(0);
  const [doneText, setDoneText] = useState<string[]>([]);

  const isDoneJob = useMemo(() => doneText.length === texts.length, [doneText]);

  setTimeout(() => {
    if (!isDoneJob) {
      setTik((tik) => tik + 1);
    }
  }, 35);

  useEffect(() => {
    const currentFullRunningText = texts[doneText.length];
    if (!currentFullRunningText) {
      return;
    }
    const isDone = tik > currentFullRunningText.length;
    if (isDone) {
      setTik(0);
      setDoneText((old) => [...old, currentFullRunningText]);
    }
  }, [tik, setTik, doneText, setDoneText]);

  const runningText = useMemo(() => {
    const currentFullRunningText = texts[doneText.length];
    if (!currentFullRunningText) {
      return;
    }
    return currentFullRunningText.slice(0, tik);
  }, [doneText, tik]);

  return (
    <div className="mission">
      <h1>Our mission</h1>
      <img src="/hostages-poster.png" alt="our mission" />
      <div className="text-container">
        <div>
          {doneText.map((text) => (
            <p>{text}</p>
          ))}
        </div>
        <div>
          <p>{runningText}</p>
        </div>
      </div>
    </div>
  );
};

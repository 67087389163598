import React, { useMemo, useState } from "react";
import { ALL_CATEGORY, useContacts } from "./useContacts";
import { useSearchParams } from "react-router-dom";
import { CategoriesFilter } from "../shared/CategoriesFilter";
import { ContactItem } from "./ContactItem";
import { updateSeoContent } from "../../util/seo";
import { ContactType } from "../../model/trueinformationonly";

export const Donations = () => {
  const [activeCategory, setActiveCategory] = useState<string | undefined>(
    ALL_CATEGORY,
  );
  const {
    contacts,
    showCategories,
    activeContact,
    categories,
    setActiveContact,
    resetActiveContent,
  } = useContacts(true);
  const [_, setSearchParams] = useSearchParams();

  const filteredContacts = useMemo(
    () =>
      contacts.filter(
        (contact) =>
          contact.type === ContactType.DONATION &&
          (activeCategory === "All" ||
            (contact.subType &&
              contact.subType.toLowerCase() ===
                (activeCategory || "").toLowerCase())),
      ),
    [contacts, activeCategory],
  );

  return (
    <div className="donation contacts">
      <h1>Donations</h1>
      <CategoriesFilter
        show={showCategories}
        activeCategory={activeCategory}
        categoriesList={categories}
        setActiveCategory={(cat: string) => {
          setActiveCategory(cat);
          resetActiveContent();
          setSearchParams((old) => {
            const newParams = { ...old };
            // @ts-ignore
            delete newParams["contact"];
            return newParams;
          });
        }}
      />
      <div className="contacts-container">
        {filteredContacts.map((contact, index) => (
          <ContactItem
            contact={contact}
            onClick={(c: string) => {
              setActiveContact(c);
              updateSeoContent(contact.orgName);
            }}
            key={index}
            isActive={activeContact === contact.orgName}
          />
        ))}
      </div>
    </div>
  );
};

import React from "react";
import { MobileMenu } from "./MobileMenu";
import { Link } from "react-router-dom";

export const Header = () => {
  return (
    <div className="header">
      <div className="inner">
        <Link to="/">
          <img src="/logo.jpeg" alt="Israel" className="logo" />
        </Link>
        <div className="title">
          <b>The Israeli Consulate in NY</b> <span className="break">|</span>{" "}
          <div className="sub-title">October 7th - For the world to know</div>
        </div>
        <MobileMenu />
      </div>
    </div>
  );
};

import { useCallback, useEffect, useState } from "react";
import { ContactModel } from "../../model/trueinformationonly";
import { queryApi } from "../../api/queryApi";
import { useSearchParams } from "react-router-dom";
import { categoryDisplayName } from "./Contacts";

export const ALL_CATEGORY = "All";

export const useContacts = (filterBySubType = false) => {
  const [contacts, setContacts] = useState<ContactModel[]>([]);
  const [showCategories, setShowCategories] = useState(false);
  const [categories, setCategories] = useState<string[]>([]);
  const [searchParams] = useSearchParams();
  const [activeContact, setActiveContact] = useState<string | null>(null);

  const getContacts = useCallback(async () => {
    const contacts = await queryApi.getContacts();
    setContacts(contacts);

    const categoriesSet = new Set<string>();
    categoriesSet.add(ALL_CATEGORY);
    const categories = contacts.reduce((set, contact) => {
      const categoryType = filterBySubType
        ? contact.subType
        : categoryDisplayName(contact.type);
      set.add(categoryType);
      return set;
    }, categoriesSet);
    const filteredCategories = Array.from(categories).filter((x) => x);

    setCategories(filteredCategories);
  }, [setContacts, filterBySubType]);

  useEffect(() => {
    getContacts();
    const maybeActiveContact = searchParams.get("contact");
    setActiveContact(maybeActiveContact);
  }, [getContacts, searchParams, setActiveContact]);

  useEffect(() => {
    setTimeout(() => setShowCategories(true), 600);
  }, [setShowCategories]);

  const resetActiveContent = useCallback(() => {
    setActiveContact(null);
  }, [setActiveContact]);

  return {
    contacts,
    categories,
    showCategories,
    activeContact,
    setActiveContact,
    resetActiveContent,
  };
};

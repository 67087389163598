import React, { useCallback, useEffect, useMemo, useState } from "react";
import { MediaModel } from "../../model/trueinformationonly";
import dayjs from "dayjs";
import { LinkItemPreview } from "./LinkItemPreview";
import copy from "copy-to-clipboard";
import toast from "react-hot-toast";
import { Tooltip } from "react-tooltip";
import classNames from "classnames";
import { useSearchParams } from "react-router-dom";
import {
  InstagramEmbed,
  TikTokEmbed,
  YouTubeEmbed,
} from "react-social-media-embed";
import { tikTokApi } from "../../api/tiktok";
import { TwitterTweetEmbed } from "react-twitter-embed";
import { MfaApproved } from "../shared/MfaApproved";
import { updateSeoContent } from "../../util/seo";
import { Modal } from "../modal/Modal";

interface FileTemplateProps extends FileWrapper {}

interface FileWrapper {
  file: MediaModel;
}

declare module "dayjs" {
  interface Dayjs {
    fromNow(): string;
  }
}

const isInstagramLink = (file: MediaModel) =>
  file.url.includes("instagram.com");

const isTikTok = (file: MediaModel) => file.url.includes("tiktok.com");

const isTwitter = (file: MediaModel) => file.url.includes("twitter.com");

const isYoutube = (file: MediaModel) => file.url.includes("youtu");

const isMediaItem = (file: MediaModel) =>
  [isInstagramLink, isTikTok, isTwitter, isYoutube].some((fn) => fn(file));

const longestPartInUrl = (url: string, avoidString: string[]) => {
  const parts = url.split("/");
  let longest = 0;
  let id = "";
  for (let part of parts) {
    if (avoidString.includes(part)) {
      continue;
    }

    if (part.length > longest) {
      id = part;
      longest = part.length;
    }
  }
  return id;
};

const getDriveId = (driveLink: string) => {
  if (!driveLink.includes("google") || !driveLink.includes("drive")) {
    return null;
  }

  return longestPartInUrl(driveLink, ["google", "drive"]);
};

const getTwitterId = (twitterUrl: string) => {
  if (!twitterUrl.includes("twitter.com")) {
    return null;
  }

  return longestPartInUrl(twitterUrl, ["twitter"]);
};

export const nameWithoutSuffix = (name: string) => {
  const [n] = name.split(".");
  return n;
};

export const InstagramFrame = ({ file }: FileWrapper) => (
  <div style={{ display: "flex", justifyContent: "center", maxWidth: 600 }}>
    <InstagramEmbed url={file.url} width={350} />
  </div>
);

export const TikTokEmbedded = ({ file }: FileWrapper) => (
  <div style={{ display: "flex", justifyContent: "center", maxWidth: 600 }}>
    <TikTokEmbed url={file.url} width={450} />
  </div>
);

export const TikTokThumbnail = ({ file }: FileWrapper) => {
  const [imgUrl, setImgUrl] = useState("");
  const fetchImage = useCallback(async () => {
    const imageUrl = await tikTokApi.getThumbnail(file.url);
    setImgUrl(imageUrl);
  }, [file.url]);

  useEffect(() => {
    fetchImage();
  }, [fetchImage]);

  if (imgUrl) {
    return <img src={imgUrl} alt={file.name} className="media-iframe" />;
  }

  return null;
};

const YouTubeThumbnail = ({ file }: FileWrapper) => {
  const regExp =
    /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
  const match = file.url.match(regExp);
  const id = match && match[7].length === 11 ? match[7] : null;
  if (!id) {
    return null;
  }

  return (
    <img
      src={`https://img.youtube.com/vi/${id}/hqdefault.jpg`}
      alt={file.name}
      className="media-iframe"
    />
  );
};

const YouTubeMediaFrame = ({ file }: FileWrapper) => (
  <div
    style={{
      display: "flex",
      justifyContent: "center",
    }}
  >
    <YouTubeEmbed
      height={220}
      placeholderImageUrl={file.name}
      url={file.url}
      width={325}
    />
  </div>
);

interface MediaProps extends FileWrapper {
  showFullMedia: boolean;
  handleClick?: () => void;
}

export const Media = ({ file, showFullMedia, handleClick }: MediaProps) => {
  const driveId = getDriveId(file.url);
  if (driveId) {
    if (showFullMedia) {
      return <MediaIframe driveId={driveId} />;
    }

    return <DriveThumbnail file={file} handleClick={handleClick} />;
  }

  if (isInstagramLink(file)) {
    return <InstagramFrame file={file} />;
  }

  if (isTikTok(file)) {
    if (showFullMedia) {
      return <TikTokEmbedded file={file} />;
    }

    return <TikTokThumbnail file={file} />;
  }

  if (isYoutube(file)) {
    if (showFullMedia) {
      return <YouTubeMediaFrame file={file} />;
    }
    return <YouTubeThumbnail file={file} />;
  }

  if (isTwitter(file)) {
    return <TwitterTweetEmbed tweetId={getTwitterId(file.url) || ""} />;
  }

  return <div className="media-iframe"></div>;
};

export const MediaIframe = ({ driveId }: { driveId: string }) => {
  const title = `preview-${driveId}`;
  const src = `https://drive.google.com/file/d/${driveId}/preview`;
  return (
    <iframe
      title={title}
      src={src}
      allow="autoplay"
      className="media-iframe shine"
    ></iframe>
  );
};

interface DriveThumbnailPops extends FileWrapper {
  handleClick?: () => void;
}

export const DriveThumbnail = ({ file, handleClick }: DriveThumbnailPops) => (
  <img
    src={`https://drive.google.com/thumbnail?id=${getDriveId(file.url)}`}
    className="media-iframe"
    alt={file.name}
    onClick={handleClick ? handleClick : () => {}}
  />
);

interface FileButtonsProps extends FileWrapper {
  hideMobile?: boolean;
  isMedia: boolean;
}

export const FileButtons = ({
  file,
  isMedia,
  hideMobile = false,
}: FileButtonsProps) => {
  const handleOpen = () => window.open(file.url);
  const handleCopy = () => {
    copy(file.url);
    toast("Copy to clipboard", { duration: 2000, position: "top-right" });
  };

  return (
    <div
      className={classNames("file-action-buttons flex", {
        "hide-mobile": hideMobile,
        media: isMedia,
      })}
    >
      <button onClick={handleOpen}>
        <img src="/download-icon.svg" alt="download" />
      </button>
      <button onClick={handleCopy}>
        <img src="/copy-link-icon.svg" alt="download" />
      </button>
    </div>
  );
};

export const FileDescription = ({ file }: FileWrapper) => (
  <div className="description">{file.description ?? ""}</div>
);

export const FileMetadata = ({ file }: FileWrapper) => (
  <div className="metadata flex">
    <div className="date">{dayjs(new Date(file.insertDate)).fromNow()}</div>
    {file.approved && <MfaApproved />}
  </div>
);

interface FileTagsProps extends FileWrapper {
  limit?: number;
}

export const FileTags = ({ file, limit = 100 }: FileTagsProps) => {
  const tags = (file.tags || "")
    .split(",")
    .map((s) => s.trim())
    .filter((s) => !!s);
  const tagsToShow = tags.slice(0, limit);

  const tagsEl = tagsToShow.map((text, index) => (
    <div
      className="tag"
      key={index}
      data-tooltip-id={`tag-${file.id}`}
      data-tooltip-content={text}
    >
      {text}
    </div>
  ));

  return (
    <div className="tags flex">
      {tagsEl}
      {tags.length > limit && (
        <div
          className="tag"
          data-tooltip-id={`tag-${file.id}-more`}
          data-tooltip-content={tags.join(", ")}
        >
          ...
        </div>
      )}
    </div>
  );
};

export const LinkItem = ({ file }: FileTemplateProps) => {
  const [showPreview, setShowPreview] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  const openPreview = useCallback(() => {
    setSearchParams((p) => ({ ...p, media: file.name }));
    updateSeoContent(file.name);
  }, [file, setSearchParams]);

  useEffect(() => {
    if (searchParams.get("media") === file.name) {
      setShowPreview(true);
      updateSeoContent(file.name);
    }
  }, [file, setShowPreview, searchParams]);

  const isMedia = useMemo(() => isMediaItem(file), [file]);

  const hidePreview = useCallback(
    (e: any) => {
      e.stopPropagation();
      setSearchParams((p) => {
        const newParam = { ...p };
        // @ts-ignore
        delete newParam["media"];
        return newParam;
      });
      setShowPreview(false);
    },
    [setSearchParams, setShowPreview],
  );

  useEffect(() => {
    const handleEsc = (e: KeyboardEvent) => {
      if (e.key === "Escape") {
        hidePreview(e);
      }
    };
    window.addEventListener("keydown", handleEsc);

    return () => {
      window.removeEventListener("keydown", handleEsc);
    };
  }, [setShowPreview, hidePreview]);

  return (
    <div
      className={classNames("file-template", {
        "social-media": isInstagramLink(file) || isTwitter(file),
        "preview-open": showPreview,
      })}
      onClick={openPreview}
    >
      <Tooltip id={`tag-${file.id}-more`} />
      <Tooltip id={`tag-${file.id}`} />

      <Modal
        show={showPreview}
        handleHide={hidePreview}
        content={<LinkItemPreview handleHide={hidePreview} file={file} />}
      />

      <div className="thumbnail">
        <Media file={file} showFullMedia={false} handleClick={openPreview} />
      </div>
      <div className="details flex">
        <FileMetadata file={file} />
        <div className="file-name">{nameWithoutSuffix(file.name)}</div>
        <FileDescription file={file} />
        <div className={classNames("actions", { flex: !isMedia })}>
          <FileTags file={file} limit={3} />
          <FileButtons file={file} hideMobile={true} isMedia={isMedia} />
        </div>
      </div>
    </div>
  );
};

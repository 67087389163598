import React from "react";

interface ModalProps {
  show: boolean;
  handleHide: (e: any) => void;
  content: React.ReactNode;
}

export const Modal = ({ handleHide, content, show }: ModalProps) => {
  if (!show) {
    return null;
  }
  return (
    <div className="preview-modal">
      <div className="overlay" onClick={handleHide}></div>
      <div className="content">{content}</div>
    </div>
  );
};

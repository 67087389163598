import { createBrowserRouter } from "react-router-dom";
import { Page } from "./Page";
import { Homepage } from "../homepage/Homepage";
import { Login } from "../login/Login";
import React from "react";
import { Contacts } from "../contacts/Contacts";
import { Donations } from "../contacts/Donations";
import { MissionPage } from "../missionPage/MissionPage";
import { ContactPage } from "../contactPage/ContactPage";
import { EXPERIMENTS } from "../../util/experiments";
import { EventsPage } from "../events/EventsPage";

const children = [
  { path: "", element: <Homepage /> },
  { path: "contacts", element: <Contacts /> },
  { path: "donations", element: <Donations /> },
  { path: "mission", element: <MissionPage /> },
  { path: "events", element: <EventsPage /> },
];

if (EXPERIMENTS.CONTACT) {
  children.push({
    path: "contact",
    element: <ContactPage />,
  });
}

export const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <>
        <Page />
      </>
    ),
    children,
  },

  {
    path: "/login",
    element: <Login />,
  },
]);

import React from "react";
import { Outlet, useLocation } from "react-router-dom";
import { Header } from "./Header";
import classNames from "classnames";

export const Page = () => {
  const location = useLocation();

  return (
    <div
      className={classNames("page", {
        "homepage-container": location.pathname === "/",
      })}
    >
      <Header />
      <div className="content-container flex  mobile-scroll">
        {/* The page layout was changed from sidebar to open by click menu. */}
        {/*<div className="hide-mobile hide-small-size">*/}
        {/*  <Sidebar />*/}
        {/*</div>*/}
        <div className="outlet mobile-scroll">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

import React, { useEffect, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import classNames from "classnames";
import { MfaApproved } from "../shared/MfaApproved";
import { ContactModel } from "../../model/trueinformationonly";
import { categoryDisplayName } from "./Contacts";

export interface ContactsItemProps {
  contact: ContactModel;
  onClick: (id: string) => void;
  isActive: boolean;
}

const isFull = (arr: string[]) => arr.length && !!arr[0];

const formatUrlToDisplay = (url: string) =>
  url.replace("http://", "").replace("https://", "");

export const ContactItem = ({
  contact,
  onClick,
  isActive,
}: ContactsItemProps) => {
  const [_, setSearchParams] = useSearchParams();
  const ref = useRef<HTMLDivElement>(null);

  const handleClick = () => {
    setSearchParams((p) => ({ ...p, contact: contact.orgName }));
    onClick(contact.id);
  };

  useEffect(() => {
    if (isActive) {
      ref?.current?.scrollIntoView({ behavior: "smooth", inline: "nearest" });
    }
  }, [ref, isActive]);

  return (
    <div
      className={classNames("contact", { active: isActive })}
      ref={ref}
      onClick={handleClick}
      id={`contact=${contact.id}`}
    >
      <h3 className="title">
        {contact.orgName} <span className="divider">/ </span>
        <span className="type">{categoryDisplayName(contact.type)}</span>{" "}
        <MfaApproved />
      </h3>
      <div className="flex no-mobile-flex details">
        {isFull(contact.peopleNames) && (
          <div className="column no-left-padding">
            <div className="label">Person of Contacts</div>
            {contact.peopleNames.map((peron, i) => (
              <div className="content" key={i}>
                {peron}
              </div>
            ))}
          </div>
        )}
        {isFull(contact.websites) && (
          <div className="column">
            <div className="label">Websites</div>
            {contact.websites.map((website, i) => (
              <div className="content" key={i}>
                <a href={website} target="_blank" rel="noreferrer">
                  {formatUrlToDisplay(website)}
                </a>
              </div>
            ))}
          </div>
        )}
        {isFull(contact.emails) && (
          <div className="column">
            <div className="label">Email</div>
            {contact.emails.map((email, i) => (
              <div className="content" key={i}>
                {email}
              </div>
            ))}
          </div>
        )}
        {isFull(contact.phoneNumbers) && (
          <div className="column">
            <div className="label">Phones</div>
            {contact.phoneNumbers.map((phone, i) => (
              <div className="content" key={i}>
                {phone}
              </div>
            ))}
          </div>
        )}
      </div>
      <div className="message">
        {contact.message?.split("\n").map((str, i) => <p key={i}>{str}</p>)}
      </div>
    </div>
  );
};

import React, { useState } from "react";
import classNames from "classnames";
import { DEFAULT_CATEGORY } from "../contentLibrary/ContentLibrary";

interface CategoriesFilterProps {
  categoriesList: string[];
  activeCategory: string | undefined;
  setActiveCategory: (c: string) => void;
  show: boolean;
}

const MobileMenu = ({
  activeCategory,
  categoriesList,
  setActiveCategory,
}: CategoriesFilterProps) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div className="mobile-categories-filter flex hide-large-size hide-small-size">
      <div
        className="active-item flex"
        onClick={() => setIsOpen((isOpen) => !isOpen)}
      >
        <div>{activeCategory}</div>
        <img src="/down-icon.svg" className="icon" />
      </div>
      {isOpen && (
        <div className="menu">
          {categoriesList.map((cat) => (
            <div
              onClick={() => {
                setIsOpen(false);
                setActiveCategory(cat);
              }}
            >
              {cat}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export const CategoriesFilter = ({
  categoriesList,
  activeCategory,
  setActiveCategory,
  show,
}: CategoriesFilterProps) => {
  const categories = categoriesList.map((text, index) => {
    const isActive = text === activeCategory;
    const displayText = text === DEFAULT_CATEGORY ? "All" : text;
    return (
      <div
        className={classNames("item", {
          active: isActive,
          "hide-animation": !show,
        })}
        onClick={() => setActiveCategory(text)}
        key={index}
      >
        {displayText}
      </div>
    );
  });

  return (
    <>
      <div className="categories-filter flex hide-mobile">{categories}</div>
      <MobileMenu
        categoriesList={categoriesList}
        activeCategory={activeCategory}
        setActiveCategory={setActiveCategory}
        show={show}
      />
    </>
  );
};

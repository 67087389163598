import {
  AnnouncementsModel,
  ContactModel,
  KeyValueReturnResponse,
  MediaQueryPaginationResponse,
} from "../model/trueinformationonly";

class QueryApi {
  private async getRequest<T>(path: string): Promise<T> {
    const response = await fetch(path, {
      method: "GET",
    });

    return response.json();
  }

  async findContent(
    query: string,
    page: number,
    category: string,
  ): Promise<MediaQueryPaginationResponse> {
    return this.getRequest(
      `/api/media?query=${query}&page=${page}&category=${category}`,
    );
  }

  async findAnnouncements(): Promise<AnnouncementsModel[]> {
    return this.getRequest(`/api/announcements`);
  }

  async getCategories(): Promise<string[]> {
    return this.getRequest(`/api/media/categories`);
  }

  async getKeyValue(): Promise<KeyValueReturnResponse> {
    return this.getRequest(`/api/key-value`);
  }

  async getContacts(): Promise<ContactModel[]> {
    return this.getRequest(`/api/contacts`);
  }
}

export const queryApi = new QueryApi();

import React, { useCallback, useEffect, useMemo, useState } from "react";
import { AnnouncementsModel } from "../../model/trueinformationonly";
import { queryApi } from "../../api/queryApi";
import "./style.css";
import dayjs from "dayjs";

export const Announcements = () => {
  const [ann, setAnn] = useState<AnnouncementsModel[]>([]);

  const getAnnouncements = useCallback(async () => {
    const ann = await queryApi.findAnnouncements();
    setAnn(ann);
  }, [setAnn]);

  useEffect(() => {
    getAnnouncements();
  }, [getAnnouncements]);

  const list = useMemo(() => {
    const totalAnn = ann.length;
    return ann
      .slice(Math.max(totalAnn - 5, 0), totalAnn)
      .reverse()
      .map((an) => (
        <div className="announcement flex" key={an.id}>
          <img src="megaphone.svg" className="icon" alt="announcement" />
          <div className="content">
            <div className="date">
              {dayjs(new Date(an.createdDate)).fromNow()}
            </div>
            <div>
              {an.content.split("\n").map((str) => (
                <p>{str}</p>
              ))}
            </div>
          </div>
        </div>
      ));
  }, [ann]);

  if (!ann.length) {
    return null;
  }

  return (
    <div className="announcements">
      <h2>Announcements</h2>
      <div>{list}</div>
    </div>
  );
};

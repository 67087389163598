import React, { useMemo } from "react";
import "./style.css";
import { ContactType } from "../../model/trueinformationonly";
import { updateSeoContent } from "../../util/seo";
import { ContactItem } from "./ContactItem";
import { useContacts } from "./useContacts";

export const categoryDisplayName = (type: ContactType | string) =>
  type.toLowerCase();

export const Contacts = () => {
  const { contacts, activeContact, setActiveContact } = useContacts();

  const filteredContacts = useMemo(
    () => contacts.filter((contact) => contact.type !== ContactType.DONATION),
    [contacts],
  );

  return (
    <div className="contacts">
      <h1>Contacts</h1>
      <div className="info">
        Inquiries, volunteering opportunities, information or general questions
        for the Consulate General of Israel in New York (for NY, NJ, PA, OH &
        DE).
        <ul>
          <li>
            <a href="mailto:mailto:standwithisrael.consulateny@gmail.com">
              standwithisrael.consulateny@gmail.com
            </a>
          </li>
          <li>
            <a href="https://embassies.gov.il/new-york/AboutTheEmbassy/Pages/About-the-embassy.aspx">
              Any other inquires
            </a>
          </li>
        </ul>
      </div>
      <div className="contacts-container">
        {filteredContacts.map((contact, index) => (
          <ContactItem
            contact={contact}
            onClick={(c: string) => {
              setActiveContact(c);
              updateSeoContent(contact.orgName);
            }}
            key={index}
            isActive={activeContact === contact.orgName}
          />
        ))}
      </div>
    </div>
  );
};

import React, { useCallback, useEffect, useRef, useState } from "react";
import { SearchBox } from "./SearchBox";
import { SearchResults } from "./SearchResults";
import "./style.css";
import { queryApi } from "../../api/queryApi";
import { CategoriesFilter } from "../shared/CategoriesFilter";
import { memoryHideWarning, ResetWarning, WarningBox } from "./WarningBox";
import { Pagination } from "./Pagination";
import { useSetRecoilState } from "recoil";
import { filesAtom } from "../../atom/filesAtom";

export const DEFAULT_CATEGORY = "";

export const ContentLibrary = () => {
  const [categories, setCategories] = useState<string[]>([DEFAULT_CATEGORY]);
  const [activeCategory, setActiveCategory] = useState(DEFAULT_CATEGORY);
  const [showCategories, setShowCategories] = useState(false);
  const [showWarning, setShowWarning] = useState(!memoryHideWarning);
  const topOfPageRef = useRef<HTMLDivElement>(null);
  const setFiles = useSetRecoilState(filesAtom);

  const doSearch = useCallback(
    async (query: string, page?: number) => {
      topOfPageRef.current?.scrollIntoView({
        behavior: "smooth",
        inline: "nearest",
      });

      const newPageIndex = page ? page : 0;
      setFiles((state) => ({
        ...state,
        isLoading: true,
        query,
      }));
      const paginationMedia = await queryApi.findContent(
        query,
        newPageIndex,
        activeCategory,
      );
      setFiles((state) => ({
        ...state,
        isLoading: false,
        isLoaded: true,
        paginationMedia,
      }));
    },
    [setFiles, topOfPageRef, activeCategory],
  );

  const getCategories = useCallback(async () => {
    const categories = (await queryApi.getCategories()).filter(
      (x) => x !== DEFAULT_CATEGORY,
    );

    setCategories([DEFAULT_CATEGORY, ...categories]);
    setTimeout(() => setShowCategories(true), 200);
  }, [setCategories, setShowCategories]);

  useEffect(() => {
    getCategories();
  }, [getCategories]);

  return (
    <div className="content-library mobile-scroll">
      <h2 className="flex" ref={topOfPageRef}>
        Content Library
      </h2>
      <ResetWarning show={showWarning} reset={() => setShowWarning(true)} />
      <WarningBox show={showWarning} handleHide={() => setShowWarning(false)} />
      <SearchBox doSearch={doSearch} />
      <CategoriesFilter
        categoriesList={categories}
        activeCategory={activeCategory}
        setActiveCategory={setActiveCategory}
        show={showCategories}
      />
      <SearchResults activeCategory={activeCategory} />
      <Pagination doSearch={doSearch} />
    </div>
  );
};

import React from "react";
import {
  FileButtons,
  FileDescription,
  FileMetadata,
  FileTags,
  Media,
  nameWithoutSuffix,
} from "./LinkItem";
import { MediaModel } from "../../model/trueinformationonly";

interface PreviewModalProps {
  handleHide: (e: any) => void;
  file: MediaModel;
}

export const LinkItemPreview = ({ handleHide, file }: PreviewModalProps) => {
  return (
    <div>
      <div className="flex">
        <FileMetadata file={file} />
        <img
          src="close-icon.svg"
          className="close-icon"
          alt="close preview"
          onClick={handleHide}
        />
      </div>
      <div className="file-name">{nameWithoutSuffix(file.name)}</div>
      <div className="tags-container">
        <FileTags file={file} />
      </div>
      <Media file={file} showFullMedia={true} />
      <FileDescription file={file} />
      <div className="line" />
      <FileButtons file={file} isMedia={false} />
    </div>
  );
};

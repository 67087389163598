import React from "react";
import { RecoilRoot } from "recoil";
import { RouterProvider } from "react-router";
import { router } from "./router";
import "./style.css";
import "../modal/style.css";
import { Toaster } from "react-hot-toast";

function App() {
  return (
    <div className="app">
      <Toaster />
      <RecoilRoot>
        <RouterProvider router={router} />
      </RecoilRoot>
    </div>
  );
}

export default App;

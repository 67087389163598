import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import classNames from "classnames";
import { isNaN } from "lodash";

const HIDE_VIDE = "hide-video";

interface MediaItem {
  url: string;
  title: string;
  credit: React.JSX.Element;
  isVideo: boolean;
}

const videos: MediaItem[] = [
  {
    isVideo: false,
    url: "/cover-image-1.jpeg",
    title: "Time Square",
    credit: <div>Consulate General of Israel in NY</div>,
  },
  {
    isVideo: true,
    url: "https://storage.googleapis.com/true-information-only-public/cover-video-1.mp4",
    title: "Imagine your child in prison",
    credit: (
      <>
        By Gili Dolev, Hila Pachter, Shirley Oran &{" "}
        <a href="https://pilanimation.com/" target="_blank" rel="noreferrer">
          Pil Animation
        </a>
      </>
    ),
  },
  {
    isVideo: true,
    url: "https://storage.googleapis.com/true-information-only-public/cover-3.mov",
    title: "Women brutally kidnapped",
    credit: (
      <>
        By{" "}
        <a
          href="https://www.instagram.com/explain.israel/"
          target="_blank"
          rel="noreferrer"
        >
          Explain Israel
        </a>
      </>
    ),
  },
];

interface MediaItemProps {
  setIndex: any;
  setProgress: any;
  mediaItem: MediaItem;
  videoRef?: any;
}

const ImageItem = ({ setIndex, setProgress, mediaItem }: MediaItemProps) => {
  const [time, setTime] = useState(0);
  useEffect(() => {
    const interval = setInterval(() => {
      setTime((t) => {
        setProgress(t + 1);
        return t + 1;
      });
    }, 50);

    return () => clearInterval(interval);
  }, [setTime, setProgress]);

  useEffect(() => {
    if (time > 99) {
      setProgress(0);
      setIndex((current: number) => current + 1);
    }
  }, [time, setIndex, setProgress]);

  return <img src={mediaItem.url} alt={mediaItem.title} />;
};

const VideoItem = ({
  setIndex,
  setProgress,
  mediaItem,
  videoRef,
}: MediaItemProps) => {
  useEffect(() => {
    if (!!videoRef.current) {
      videoRef.current.muted = true;
      videoRef.current.loop = true;
      videoRef.current.playbackRate = 1.3;
      setTimeout(() => videoRef.current?.play(), 500);
    }
  });

  const handleVideoEnd = useCallback(() => {
    setIndex((current: number) => current + 1);
  }, [setIndex]);

  const handleProgress = useCallback(
    (e: any) => {
      const { currentTime, duration } = e.target;
      const progress = Math.round((currentTime / duration) * 100);
      if (!isNaN(progress)) {
        setProgress(progress);
      }
    },
    [setProgress],
  );

  return (
    <video
      ref={videoRef}
      onEnded={handleVideoEnd}
      onTimeUpdate={handleProgress}
    >
      <source id={mediaItem.url} src={mediaItem.url} type="video/mp4" />
      Your browser does not support the video tag.
    </video>
  );
};

export const VideoCarousel = () => {
  const [index, setIndex] = useState(0);
  const [progress, setProgress] = useState(0);
  const [show, setShow] = useState(localStorage.getItem(HIDE_VIDE) !== "true");
  const videoRef = useRef<HTMLVideoElement>(null);

  const mediaItem = useMemo(() => videos[index], [index]);

  const buttons = useMemo(() => {
    return (
      <div className="circles flex">
        {videos.map((menuItem, currentIndex) => {
          const selected = currentIndex === index;
          const handleVideoChange = () => {
            setProgress(0);
            setIndex(currentIndex);
            if (menuItem.isVideo) {
              videoRef.current?.load();
              videoRef.current?.play();
            }
          };

          return (
            <div
              key={currentIndex}
              className={classNames("circle", { selected })}
              onClick={() => handleVideoChange()}
            >
              {selected && (
                <div
                  className="progress"
                  style={{ width: `${progress}%` }}
                ></div>
              )}
            </div>
          );
        })}
      </div>
    );
  }, [index, setIndex, progress, setProgress]);

  if (!show) {
    return (
      <div>
        <button
          onClick={() => {
            localStorage.removeItem(HIDE_VIDE);
            setShow(true);
          }}
        >
          Show Video
        </button>
      </div>
    );
  }

  const Component = mediaItem.isVideo ? VideoItem : ImageItem;

  return (
    <div className="video-carousel hide-mobile">
      <div className="cover">
        <button
          onClick={() => {
            localStorage.setItem(HIDE_VIDE, "true");
            setShow(false);
          }}
        >
          Hide video
        </button>
        <div className="text flex">
          <div className="title">{mediaItem.title}</div>
          <div className="credit">{mediaItem.credit}</div>
        </div>
        {buttons}
      </div>
      <Component
        setIndex={setIndex}
        setProgress={setProgress}
        mediaItem={mediaItem}
        videoRef={videoRef}
      />
    </div>
  );
};

import React from "react";

export const Login = () => {
  const handleLogin = () => {
    fetch("logintes", {
      headers: {
        Authorization: "Basic " + window.btoa("test:test"),
      },
    }).then((resp) => {
      console.log(resp);
    });
  };

  return (
    <div className="login">
      <h1>Login</h1>
      <input name="username" />
      <input name="password" type="password" />
      <button type="submit" onClick={handleLogin}>
        Login
      </button>
    </div>
  );
};

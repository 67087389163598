import React from "react";

export const HIDE_WARNING = "hide-warning";
export const memoryHideWarning = !!localStorage.getItem(HIDE_WARNING);

interface ResetWarningProps {
  show: boolean;
  reset: () => void;
}

export const ResetWarning = ({ show, reset }: ResetWarningProps) => {
  if (!show) {
    return (
      <div className="show-warning" onClick={reset}>
        Warning
      </div>
    );
  }
  return null;
};

interface WarningBoxProps {
  show: boolean;
  handleHide: () => void;
}

export const WarningBox = ({ show, handleHide }: WarningBoxProps) => {
  if (!show) {
    return null;
  }

  const handleButtonClick = () => {
    handleHide();
    localStorage.setItem(HIDE_WARNING, "hide");
  };

  return (
    <div className="warning">
      <div className="warning-container flex no-mobile-flex">
        <div className="label">notice</div>
        <div className="text">
          Visuals, voices, and testimonials are heartbreaking. They are
          difficult to watch, hear, and read. Please ensure that children and
          those who might be triggered are kept away. This is the reality of
          what happened, and the world needs to be aware and take a stand{" "}
          <button onClick={handleButtonClick}>Hide</button>
        </div>
      </div>
    </div>
  );
};

/* tslint:disable */
/* eslint-disable */
// Generated using typescript-generator version 3.2.1263 on 2023-10-26 15:00:59.

export interface AnnouncementsModel {
  id: string;
  content: string;
  createdDate: Date;
}

export interface AnnouncementsModelBuilder {}

export interface ContactModel {
  id: string;
  type: ContactType;
  orgName: string;
  peopleNames: string[];
  phoneNumbers: string[];
  emails: string[];
  websites: string[];
  message: string;
  subType: string;
}

export interface ContactModelBuilder {}

export interface KeyValueReturnResponse {
  keyValue: { [P in Key]?: string };
}

export interface KeyValueReturnResponseBuilder {}

export interface KeyValueStorageModel {
  key: Key;
  value: string;
  secret: boolean;
}

export interface KeyValueStorageModelBuilder {}

export interface MediaModel {
  id: string;
  name: string;
  description: string;
  url: string;
  insertDate: Date;
  tags: string;
  category: string;
  approved: boolean;
}

export interface MediaModelBuilder {}

export interface MediaQueryPaginationResponse {
  media: MediaModel[];
  totalPages: number;
  pageIndex: number;
  query: string;
}

export interface MediaQueryPaginationResponseBuilder {}

export interface UserModel {
  uid: number;
  fullname: string;
  email: string;
  org: string;
  phone_num: string;
}

export interface UserModelBuilder {}

export enum ContactType {
  CONTACTS = "CONTACTS",
  DONATION = "DONATION",
  VOLUNTEER = "VOLUNTEER",
}

export enum Key {
  DAILY_MESSAGE = "DAILY_MESSAGE",
  SECRET = "SECRET",
  DAILY_MFA_LINK = "DAILY_MFA_LINK",
}

import React, { useCallback, useEffect, useMemo, useState } from "react";
import { ContentLibrary } from "../contentLibrary/ContentLibrary";
import "./style.css";
import { Announcements } from "../announcements/Announcements";
import { queryApi } from "../../api/queryApi";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { keyValueAtom } from "../../atom/keyValueAtom";
import { DailyMessage } from "./DailyMessage";
import { VideoCarousel } from "./VideoCarousel";
import { Modal } from "../modal/Modal";
import { MissionPage } from "../missionPage/MissionPage";
import { Link } from "react-router-dom";

const HIDE_MISSION = "hide-mission";

const Divider = () => <div className="divider"></div>;

const SocialMedia = () => (
  <div className="social-media-icons">
    <h2>Social Media</h2>
    <div className="item">
      <a
        href="https://www.instagram.com/israelinnewyork/"
        className="container flex"
        target="_blank"
        rel="noreferrer"
      >
        <img src="ig-icon.png" alt="instagram" />
        <div className="label">israelinnewyork</div>
      </a>
    </div>
    <div className="item">
      <a
        href="https://twitter.com/IsraelinNewYork"
        className="container flex"
        target="_blank"
        rel="noreferrer"
      >
        <img src="x-icon.png" alt="instagram" />
        <div className="label">IsraelinNewYork</div>
      </a>
    </div>
  </div>
);

export const Homepage = () => {
  const setKeyValues = useSetRecoilState(keyValueAtom);
  const [showPreview, setShowPreview] = useState(
    localStorage.getItem(HIDE_MISSION) !== "hide",
  );
  const keyValue = useRecoilValue(keyValueAtom);

  const loadKeyValue = useCallback(async () => {
    const response = await queryApi.getKeyValue();
    setKeyValues(response.keyValue);
  }, [setKeyValues]);

  useEffect(() => {
    loadKeyValue();
  }, [loadKeyValue]);

  const hideMission = useCallback(() => {
    setShowPreview(false);
    localStorage.setItem(HIDE_MISSION, "hide");
  }, [setShowPreview]);

  const missionModalContent = useMemo(() => {
    return (
      <>
        <MissionPage />
        <button onClick={hideMission}>Continue</button>
      </>
    );
  }, [hideMission]);

  return (
    <div className="homepage">
      <Modal
        show={showPreview}
        handleHide={hideMission}
        content={missionModalContent}
      />
      <VideoCarousel />
      <div className="flex inner-content no-mobile-flex">
        <div className="left-side">
          <DailyMessage />
          <ContentLibrary />
        </div>
        <div className="right-side">
          <SocialMedia />
          <Divider />
          <Link
            to={
              keyValue.DAILY_MFA_LINK ||
              "https://docs.google.com/document/d/1wYtuvAcCtwezXtj9TgKEKALqAVKtbj8uO4k-ng97zkU/view"
            }
            target="_blank"
            className="link-to-info flex"
          >
            <img src="/link-icon.svg" className="icon" alt="open update" />
            <div className="label">Information on the War in Israel</div>
          </Link>
          <Link
            to={"https://www.kidnappedfromisrael.com"}
            target="_blank"
            className="link-to-info kidnapped flex"
          >
            <div className="label">#KIDNAPPED</div>
          </Link>
          <Announcements />
        </div>
      </div>
    </div>
  );
};

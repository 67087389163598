import { atom } from "recoil";
import { MediaQueryPaginationResponse } from "../model/trueinformationonly";

export interface FilesData {
  paginationMedia: MediaQueryPaginationResponse;
  isLoading: boolean;
  query: string;
  isLoaded: boolean;
}

export const filesAtom = atom<FilesData>({
  key: "files",
  default: {
    paginationMedia: {
      media: [],
      totalPages: 0,
      pageIndex: 0,
      query: "",
    },
    isLoading: false,
    query: "",
    isLoaded: false,
  },
});

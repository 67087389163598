import React, { useEffect, useRef } from "react";
import { debounce } from "lodash";

interface SearchBoxProps {
  doSearch: (query: string, page?: number) => void;
}

export const SearchBox = ({ doSearch }: SearchBoxProps) => {
  const fileNameRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    doSearch("");
  }, [doSearch]);

  const onInputChange = debounce(
    () => doSearch(fileNameRef?.current?.value || ""),
    300,
    {
      maxWait: 800,
    },
  );

  return (
    <div className="search-box flex">
      <div className="label hide-mobile">Recent updates</div>
      <div className="search-box-container flex">
        <img src="search-icon.svg" className="icon" alt="search" />
        <input
          onChange={onInputChange}
          ref={fileNameRef}
          placeholder="search"
        />
      </div>
    </div>
  );
};

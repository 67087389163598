import React, { useMemo } from "react";
import { useRecoilValue } from "recoil";
import { filesAtom } from "../../atom/filesAtom";
import { LinkItem } from "./LinkItem";
import { DEFAULT_CATEGORY } from "./ContentLibrary";
import { useSearchParams } from "react-router-dom";
import { MediaModel } from "../../model/trueinformationonly";
import classNames from "classnames";

interface SearchResultsProps {
  activeCategory: string;
}

export const SearchResults = ({ activeCategory }: SearchResultsProps) => {
  const {
    paginationMedia: { media },
    isLoading,
    isLoaded,
  } = useRecoilValue(filesAtom);
  const [searchParams] = useSearchParams();

  const filesEl = useMemo(() => {
    if (!isLoaded) {
      return <div className="no-results">Loading</div>;
    }

    if (media.length === 0) {
      return <div className="no-results">No Results</div>;
    }

    return (
      media
        .filter(
          (file) =>
            activeCategory === DEFAULT_CATEGORY ||
            file.category === activeCategory,
        )
        .sort(
          (a, b) =>
            new Date(a.insertDate).getTime() - new Date(b.insertDate).getTime(),
        )
        .reverse()
        // Show the query param media first in the list.
        .reduce<MediaModel[]>((acc, item) => {
          if (searchParams.get("media") === item.id) {
            return [item, ...acc];
          }
          return [...acc, item];
        }, [])
        .map((file) => <LinkItem file={file} key={file.id} />)
    );
  }, [media, activeCategory, searchParams, isLoaded]);

  return (
    <div className={classNames("search-results", { loading: isLoading })}>
      {filesEl}
    </div>
  );
};

import React, { useMemo } from "react";
import { useRecoilValue } from "recoil";
import { filesAtom } from "../../atom/filesAtom";
import classNames from "classnames";

interface PaginationProps {
  doSearch: (query: string, page?: number) => void;
}

export const Pagination = ({ doSearch }: PaginationProps) => {
  const {
    paginationMedia: { pageIndex, totalPages, query },
    isLoading,
  } = useRecoilValue(filesAtom);

  const buttons = useMemo(() => {
    return Array(totalPages)
      .fill(0)
      .map((_, index) => {
        const handleClick = () => {
          doSearch(query, index);
        };
        return (
          <button
            onClick={handleClick}
            className={classNames({ selected: index === pageIndex })}
          >
            {index + 1}
          </button>
        );
      });
  }, [totalPages, doSearch, pageIndex, query]);

  if (isLoading) {
    return null;
  }

  return (
    <div className={classNames("pagination flex")}>
      <button onClick={() => doSearch(query, Math.max(pageIndex - 1, 0))}>
        {"<"}
      </button>
      {buttons}
      <button
        onClick={() => doSearch(query, Math.min(pageIndex + 1, totalPages - 1))}
      >
        {">"}
      </button>
    </div>
  );
};

import React, { useCallback } from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router";
import classNames from "classnames";

export const Sidebar = ({
  show,
  handleHide,
}: {
  show: boolean;
  handleHide: () => void;
}) => {
  const { pathname } = useLocation();
  const getClassNames = useCallback(
    (key: string) => classNames("menu-item", { selected: key === pathname }),
    [pathname],
  );

  return (
    <div
      className={classNames("sidebar-container", { show })}
      onClick={handleHide}
    >
      <div className={classNames("sidebar")}>
        <Link to="/" className={getClassNames("/")}>
          Homepage
        </Link>
        <Link to="/events" className={getClassNames("/events")}>
          Events
        </Link>
        <Link to="mission" className={getClassNames("/mission")}>
          Mission
        </Link>
        <Link to="contacts" className={getClassNames("/contacts")}>
          Contacts
        </Link>
        <Link to="donations" className={getClassNames("/donations")}>
          Donations
        </Link>
      </div>
    </div>
  );
};

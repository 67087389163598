import React, { useState } from "react";
import { Sidebar } from "./Sidebar";

export const MobileMenu = () => {
  const [show, setShow] = useState(false);

  return (
    <div className="mobile-menu">
      <img
        src="menu-icon.svg"
        className="icon"
        alt="menu"
        onClick={() => setShow(true)}
      />
      <Sidebar show={show} handleHide={() => setShow(false)} />
    </div>
  );
};

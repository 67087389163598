interface TikTokPostApi {
  thumbnail_url: string;
}

class TikTokApi {
  public async getThumbnail(postId: string): Promise<string> {
    const response = await fetch(
      `https://www.tiktok.com/oembed?url=https://www.tiktok.com/any/video/${postId}`,
      {
        method: "GET",
      },
    );

    const body = await response.json();
    return (body as TikTokPostApi).thumbnail_url;
  }
}

export const tikTokApi = new TikTokApi();

import React from "react";
import "./dailyCalendarIcon.css";

export const DailyCalendarIcon = () => {
  const delta = new Date().getTime() - new Date("10/7/2023").getTime();
  const days = Math.ceil(delta / 1000 / 60 / 60 / 24);
  return (
    <div className="daily-calendar-icon">
      <div className="day">Day</div>
      <div className="day-of-month">{days}</div>
    </div>
  );
};

import React, { useCallback, useMemo, useRef, useState } from "react";
import { useRecoilValue } from "recoil";
import { keyValueAtom } from "../../atom/keyValueAtom";
import { DailyCalendarIcon } from "./DailyCalendarIcon";

const TEXT_LIMIT = 200;

const splitToParagraphs = (text: string) =>
  text.split("\n").map((str, i) => <p key={i}>{str}</p>);

export const DailyMessage = () => {
  const titleRef = useRef<HTMLHeadingElement>(null);
  const [showMore, setShowMore] = useState(false);
  const keyValue = useRecoilValue(keyValueAtom);

  const handleClick = useCallback(() => {
    titleRef?.current?.scrollIntoView({
      behavior: "smooth",
      inline: "nearest",
    });
    setShowMore(!showMore);
  }, [showMore, setShowMore, titleRef]);

  const dailyMessage = useMemo(() => {
    const fullText = keyValue.DAILY_MESSAGE;
    if (!fullText) {
      return <div />;
    }

    const formattedFullText = showMore
      ? fullText.slice(TEXT_LIMIT, Number.MAX_VALUE)
      : "";
    const formattedShortText = `${fullText.slice(0, TEXT_LIMIT)} ${
      showMore ? "" : "..."
    }`;
    const buttonText = showMore ? "Show less" : "Show more";
    return (
      <div>
        <div className="text show">
          {splitToParagraphs(formattedShortText + formattedFullText)}
        </div>
        <button onClick={handleClick}>{buttonText}</button>
      </div>
    );
  }, [showMore, keyValue, handleClick]);

  return (
    <div className="daily-message">
      <div className="flex title">
        <h1 ref={titleRef}>Updates</h1>
        <DailyCalendarIcon />
      </div>
      {dailyMessage}
    </div>
  );
};
